import {
  ClaimFragment,
  ClaimStatus,
  ListClaimsDocument,
  useReviewClaimMutation,
} from '@/generated/graphql';
import Dialog from '@components/dialog/Dialog';
import FormLabel from '@components/form/FormLabel';
import { ReactNode } from 'react';
import { useState } from 'react';
import { extractGraphQLErrorIfExists } from '../../utilities/errors/helpers';

type Props = {
  claim: ClaimFragment;
  trigger: ReactNode;
  onConfirm?: () => Promise<{ error?: string } | undefined | void> | void;
};

export const ClaimRequestAdditionalInfoDialog = ({
  claim,
  trigger,
  onConfirm,
}: Props) => {
  const [reviewClaim] = useReviewClaimMutation();

  const [reason, setReason] = useState('');

  return (
    <Dialog
      title="Request additional information"
      description=""
      onConfirm={async () => {
        const res = await extractGraphQLErrorIfExists(
          reviewClaim({
            variables: {
              input: {
                id: claim.id,
                status: ClaimStatus.AwaitingInformation,
                updateReason: reason,
              },
            },
            refetchQueries: [ListClaimsDocument],
          }),
          'reviewClaim',
        );

        if (res.error) {
          return res;
        }

        setReason('');

        return onConfirm ? onConfirm() : {};
      }}
      confirmText="Submit"
      trigger={trigger}
      async
      confirmColor="brand"
    >
      <div>
        <FormLabel className="mb-4">
          Comments
          <textarea
            className="w-full rounded border border-primary p-4 shadow-inner"
            value={reason}
            onChange={(e) => setReason(e.currentTarget.value)}
          />
        </FormLabel>
      </div>
    </Dialog>
  );
};
