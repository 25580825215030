import { ClaimDetails } from '@/components/claims/ClaimDetails';
import Button from '@components/button/Button';
import Skeleton from '@components/skeleton/Skeleton';
import {
  ClaimReviewRequiredFragment,
  ClaimSourceType,
  ClaimStatus,
  useClaimQuery,
  useReviewClaimMutation,
} from 'generated/graphql';
import { extractGraphQLErrorIfExists } from 'utilities/errors/helpers';
import { ClaimRequestAdditionalInfoDialog } from '../../../claims/ClaimRequestAdditionalInfoDialog';
import { useTasksContext } from '../useTasksContext';
import { SectionHeading } from './utils/SectionHeading';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  task: ClaimReviewRequiredFragment;
  taskId: string;
};

export const ClaimReviewRequired = ({ task, taskId }: Props) => {
  const {
    data,
    error: unhandledError,
    loading,
  } = useClaimQuery({
    variables: {
      id: task.claimId,
    },
  });

  const { action } = useTasksContext();
  const [reviewClaim] = useReviewClaimMutation();

  const error = unhandledError?.message || data?.claim?.error?.message;
  const claim = data?.claim?.claim;

  return (
    <div className="flex flex-col gap-4 py-3">
      {error && <p className="text-warning">{error}</p>}

      {loading ? <Skeleton height={75} /> : null}
      {claim && (
        <>
          <ClaimDetails claim={claim} className="w-fit" />

          <SectionHeading title="Actions" />

          <TaskActionButton
            text={
              claim.sourceType === ClaimSourceType.ExternalCarrier &&
              claim.status === ClaimStatus.InReview
                ? 'Escalate to external carrier'
                : 'Approve'
            }
            role="primary"
            taskIdToCompleteOnClick={taskId}
            onClick={async () => {
              let approvedStatus = ClaimStatus.Approved;
              // If this source is an external carrier (because we believe that they are
              // responsible for an issue with the shipment) then we should escalate the
              // claim to the external carrier. This is done manually as the external
              // carriers do not have an API available for claims. To track this operation
              // when approving a external carrier claim transition the status from
              // IN_REVIEW to IN_EXTERNAL_REVIEW.
              //
              // For other claims (or for claims that have already been escalated to the
              // external carrier) we can transition the claim to the APPROVED status.
              if (claim.sourceType === ClaimSourceType.ExternalCarrier) {
                if (claim.status === ClaimStatus.InReview) {
                  approvedStatus = ClaimStatus.InExternalReview;
                }
              }

              return extractGraphQLErrorIfExists(
                reviewClaim({
                  variables: {
                    input: {
                      id: task.claimId,
                      status: approvedStatus,
                    },
                  },
                }),
                'reviewClaim',
              );
            }}
          />

          <TaskActionButton
            text="Reject"
            role="destructive"
            taskIdToCompleteOnClick={taskId}
            onClick={async () => {
              return extractGraphQLErrorIfExists(
                reviewClaim({
                  variables: {
                    input: {
                      id: task.claimId,
                      status: ClaimStatus.Rejected,
                    },
                  },
                }),
                'reviewClaim',
              );
            }}
          />
          <ClaimRequestAdditionalInfoDialog
            claim={claim}
            trigger={
              <div className="px-3">
                <Button
                  className="w-full"
                  color="neutral"
                  s="small"
                  mode="outline"
                >
                  Request additional information
                </Button>
              </div>
            }
            onConfirm={() => {
              action.optimisticCompletion(taskId);
            }}
          />
        </>
      )}
    </div>
  );
};
