import { Temporal } from '@js-temporal/polyfill';
import { ReactElement } from 'react';
import { TaskInfoTask } from '../types';
import { AddressFeedbackFromDriver } from './AddressFeedbackFromDriver';
import { AddressReview } from './AddressReview';
import { ClaimReviewRequired } from './ClaimReviewRequired';
import { CollectionCancelled } from './CollectionCancelled';
import { CollectionOnMoreThanOneRoute } from './CollectionOnMoreThanOneRoute';
import { CollectionsNotOnRoute } from './CollectionsNotOnRoute';
import { IntercomItemForAttention } from './IntercomItemForAttention';
import { PackStuckInDepot } from './PackStuckInDepot';
import { PacksLeftInVan } from './PacksLeftInVan';
import { ProofOfCompletionReview } from './ProofOfCompletionReview';
import { RouteActivitiesNeedAttention } from './RouteActivitiesNeedAttention';
import { ShipmentDateChange } from './ShipmentDateChange';
import { ShipmentNoLocationInDepot } from './ShipmentNoLocationInDepot';
import { ShipmentOnHold } from './ShipmentOnHold';
import { ShipmentOnMoreThanOneRoute } from './ShipmentOnMoreThanOneRoute';
import { ShipmentOnStartedRouteInDepot } from './ShipmentOnStartedRouteInDepot';
import { ShipmentsNotOnRoute } from './ShipmentsNotOnRoute';
import { ShipmentsOnRouteNotCollected } from './ShipmentsOnRouteNotCollected';
import { VehicleNotEnded } from './VehicleNotEnded';

type Props = {
  task: TaskInfoTask['task'];
  taskId: string;
  createdByName: string | null | undefined;
  createdByUserId: string | null | undefined;
  createdAt: Temporal.Instant;
};

export const TaskSpecificContent = ({ task, taskId }: Props): ReactElement => {
  switch (task.__typename) {
    case 'AddressFeedbackFromDriver':
      return <AddressFeedbackFromDriver task={task} taskId={taskId} />;
    case 'AddressReview':
      return <AddressReview task={task} taskId={taskId} />;
    case 'CollectionCancelled':
      return <CollectionCancelled task={task} taskId={taskId} />;
    case 'CollectionsNotOnRoute':
      return <CollectionsNotOnRoute task={task} taskId={taskId} />;
    case 'CollectionOnMoreThanOneRoute':
      return <CollectionOnMoreThanOneRoute task={task} taskId={taskId} />;
    case 'IntercomItemForAttention':
      return <IntercomItemForAttention />;
    case 'PackStuckInDepot':
      return <PackStuckInDepot task={task} taskId={taskId} />;
    case 'ProofOfCompletionReview':
      return <ProofOfCompletionReview task={task} taskId={taskId} />;
    case 'ShipmentDateChange':
      return <ShipmentDateChange task={task} taskId={taskId} />;
    case 'ShipmentNoLocationInDepot':
      return <ShipmentNoLocationInDepot task={task} taskId={taskId} />;
    case 'ShipmentsNotOnRoute':
      return <ShipmentsNotOnRoute task={task} taskId={taskId} />;
    case 'ShipmentOnHold':
      return <ShipmentOnHold task={task} taskId={taskId} />;
    case 'ShipmentOnMoreThanOneRoute':
      return <ShipmentOnMoreThanOneRoute task={task} taskId={taskId} />;
    case 'ShipmentsOnRouteNotCollected':
      return <ShipmentsOnRouteNotCollected task={task} taskId={taskId} />;
    case 'ShipmentOnStartedRouteInDepot':
      return <ShipmentOnStartedRouteInDepot task={task} taskId={taskId} />;
    case 'RouteActivitiesNeedAttention':
      return <RouteActivitiesNeedAttention task={task} taskId={taskId} />;
    case 'PacksLeftInVan':
      return <PacksLeftInVan task={task} taskId={taskId} />;
    case 'VehicleNotEnded':
      return <VehicleNotEnded task={task} taskId={taskId} />;
    case 'ClaimReviewRequired':
      return <ClaimReviewRequired task={task} taskId={taskId} />;
    default:
      return <div>Unexpected task type</div>;
  }
};
