import {
  TZ_EUROPE_LONDON,
  formatFriendlyPlainDate,
  formatInstantTime,
  parseInstant,
  parsePlainDate,
} from '@packfleet/datetime';
import { ShipmentsNotOnRouteFragment } from 'generated/graphql';
import { useEffect, useMemo } from 'react';
import { formatAddress } from 'utilities/address';
import { Routes, linkTo } from 'utilities/routes';
import { formatStartEndTime } from 'utilities/time';
import { truthy } from 'utilities/truthy';
import { MapInfo, useTasksContext } from '../useTasksContext';
import { MoveDeliveryDateButton } from './MoveDeliveryDateButton';
import { SectionHeading } from './utils/SectionHeading';
import { ShipmentsDetail } from './utils/ShipmentsDetails';
import { TaskActionButton } from './utils/TaskActionButton';

type Props = {
  task: ShipmentsNotOnRouteFragment;
  taskId: string;
};

export const ShipmentsNotOnRoute = ({ task }: Props) => {
  const { action } = useTasksContext();

  useEffect(() => {
    if (task) {
      action.displayTaskInfoOnMap(getMapMarkers(task));
    }
    return () => action.clearTaskInfoOnMap();
  }, [task]);

  const date = useMemo(() => parsePlainDate(task.date), [task.date]);

  return (
    <div className="flex flex-col gap-4 py-3">
      <ShipmentsDetail shipments={task.shipments} />

      <p className="px-3 text-secondary">{formatFriendlyPlainDate(date)}</p>

      <SectionHeading title="Actions" />
      <div className="flex flex-col gap-2">
        <TaskActionButton
          text="Add to routes"
          role="primary"
          href={linkTo(Routes.routeActive, { date: task.date })}
        />
        <MoveDeliveryDateButton date={date} shipments={task.shipments} />
      </div>
    </div>
  );
};

export function getMapMarkers(task: ShipmentsNotOnRouteFragment): MapInfo {
  return {
    markers: task.shipments
      .map((shipment) =>
        shipment.address.latitude != null && shipment.address.longitude
          ? {
              routeDate: task.date,
              id: shipment.id,
              title: `📦 ${getTitle(shipment)}`,
              location: {
                lat: shipment.address.latitude,
                lng: shipment.address.longitude,
              },
              showPopupOnHover: false,
              copyText: shipment.trackingPhrase,
              popupText: [
                shipment.trackingPhrase,
                formatAddress(shipment.address),
              ],
            }
          : null,
      )
      .filter(truthy),
  };
}

function getTitle(shipment: ShipmentsNotOnRouteFragment['shipments'][number]) {
  const trackingPhrase = shipment.trackingPhrase
    .split('-')
    .map((word) => word.charAt(0))
    .join('-');

  const timeWindow = shipment.timeWindow
    ? ` (${formatStartEndTime(
        shipment.timeWindow.startTime
          ? formatInstantTime(
              parseInstant(shipment.timeWindow.startTime),
              TZ_EUROPE_LONDON,
            )
          : null,
        shipment.timeWindow.endTime
          ? formatInstantTime(
              parseInstant(shipment.timeWindow.endTime),
              TZ_EUROPE_LONDON,
            )
          : null,
      )})`
    : '';

  return `${trackingPhrase}${timeWindow}`;
}
