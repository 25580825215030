import React, { FunctionComponent, useState } from 'react';
import Alert from './Alert';

export type Props = {
  button: React.ReactElement;
  title: string;
  body?: string;
  bodyComponent?: React.ReactNode;
  confirmText: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
};

const WithAlert: FunctionComponent<Props> = ({ button, ...props }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {React.cloneElement(button, {
        onClick: () => setShow(true),
      })}
      <Alert
        {...props}
        show={show}
        className={'max-w-sm'}
        onConfirm={() => {
          setShow(false);
          props.onConfirm();
        }}
        onCancel={() => {
          setShow(false);
          if (props.onCancel) props.onCancel();
        }}
      />
    </>
  );
};

export default WithAlert;
