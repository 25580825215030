import { ClaimStatus } from '@/generated/graphql';

export const isClaimFinalStatus = (status: ClaimStatus) => {
  switch (status) {
    case ClaimStatus.Approved:
    case ClaimStatus.Rejected:
      return true;
    default:
      return false;
  }
};

export const formatClaimStatus = (status: ClaimStatus): string => {
  switch (status) {
    case ClaimStatus.Approved:
      return 'Approved';
    case ClaimStatus.AwaitingInformation:
      return 'Awaiting Information';
    case ClaimStatus.InExternalReview:
      return 'In External Review';
    case ClaimStatus.InReview:
      return 'In Review';
    case ClaimStatus.Rejected:
      return 'Rejected';
  }
};

export const getClaimBadgeColor = (status: ClaimStatus) => {
  switch (status) {
    case ClaimStatus.Approved:
      return 'success';
    case ClaimStatus.InExternalReview:
    case ClaimStatus.InReview:
    case ClaimStatus.AwaitingInformation:
      return 'warning';
    case ClaimStatus.Rejected:
      return 'danger';
  }
};

export const getClassNamesForClaimStatus = (status: ClaimStatus) => {
  switch (status) {
    case ClaimStatus.Approved:
      return 'text-success bg-success/20';
    case ClaimStatus.InExternalReview:
    case ClaimStatus.InReview:
    case ClaimStatus.AwaitingInformation:
      return 'text-warning bg-warning/20';
    case ClaimStatus.Rejected:
      return 'text-danger bg-danger/20';
  }
};
