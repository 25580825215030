import {
  ClaimSourceType,
  ExternalShipmentWithOrgFragment,
  FullyDetailedShipmentFragment,
  ListClaimsDocument,
  StaffRole,
  useCreateClaimMutation,
} from '@/generated/graphql';
import { formatMoney } from '@/utilities/money';
import Dialog from '@components/dialog/Dialog';
import FormLabel from '@components/form/FormLabel';
import { toMoneyAmount } from '@packfleet/money';
import { ReactNode, useEffect } from 'react';
import { useState } from 'react';
import { extractGraphQLErrorIfExists } from '../../utilities/errors/helpers';
import Checkbox from '../checkbox/Checkbox';
import FormDescription from '../form/FormDescription';
import Input from '../input/Input';
import Select from '../select/Select';
import { ToggleButtons } from '../toggle-button/ToggleButtons';
import { User, UsersSelect } from '../users/UsersSelect';
import { ClaimAmountType } from './Claim';

type Props = {
  shipment: FullyDetailedShipmentFragment | ExternalShipmentWithOrgFragment;
  trigger: ReactNode;
};

const WaiveDeliveryFeeClaimType = 'Waive delivery fee';

export const SourceTypeOptions = [
  { label: 'Driver', value: ClaimSourceType.Driver },
  { label: 'Hub', value: ClaimSourceType.Hub },
  { label: 'Internal', value: ClaimSourceType.Internal },
  { label: 'External Carrier', value: ClaimSourceType.ExternalCarrier },
];

export const ClaimTypeOptions = [
  { label: 'Broken pack in hub' },
  { label: 'Broken pack in van' },
  { label: 'Collection issue' },
  { label: 'Delivered to  incorrect safe place' },
  { label: 'Delivered to wrong address' },
  { label: 'Failed delivery' },
  { label: 'Lost in hub' },
  { label: 'Missing pack in van' },
  { label: 'Multipack Shipment' },
  { label: 'Outside of ETA' },
  { label: 'Pack stolen' },
  { label: 'Poor delivery standard' },
  { label: 'Timed delivery' },
  { label: 'On-demand delivery' },
  { label: WaiveDeliveryFeeClaimType },
  { label: 'Other' },
];

export const AddClaimDialog = ({ shipment, trigger }: Props) => {
  const [createClaim] = useCreateClaimMutation();

  const [type, setType] = useState('');
  const [reason, setReason] = useState('');
  const [sourceType, setSourceType] = useState<null | ClaimSourceType>(null);
  const [sourceUser, setSourceUser] = useState<null | User>(null);
  const [allPacks, setAllPacks] = useState(true);
  const [packs, setPacks] = useState<Record<string, boolean>>({});
  const [amountsType, setAmountsType] = useState<number | boolean>(false);
  const [amount, setAmount] = useState<string | null>(null);
  const [costToPackfleet, setCostToPackfleet] = useState<string | null>(null);
  const [chargeSourceUser, setChargeSourceUser] = useState<boolean>(false);
  const [chargeSourceUserAmount, setChargeSourceUserAmount] = useState<
    string | null
  >(null);
  const [refundFees, setRefundFees] = useState<boolean>(false);

  useEffect(() => {
    setPacks(
      shipment.packs
        .map((p) => p.id)
        .reduce<Record<string, boolean>>((acc, packId) => {
          acc[packId] = false;
          return acc;
        }, {}),
    );
  }, [shipment]);

  const currency = shipment.billingCurrency || 'GBP';

  let parsedAmount = Math.round(Number(amount) * 100);
  if (isNaN(parsedAmount)) {
    parsedAmount = 0;
  }

  return (
    <Dialog
      title="Add claim"
      description=""
      onConfirm={async () => {
        if (!sourceType) return;

        const amountVal =
          amountsType !== false && amount
            ? toMoneyAmount(amount, currency) * Number(amountsType)
            : null;
        const costToPackfleetVal = costToPackfleet
          ? toMoneyAmount(costToPackfleet, currency)
          : null;
        const chargeSourceUserAmountVal = chargeSourceUserAmount
          ? toMoneyAmount(chargeSourceUserAmount, currency)
          : null;

        const res = await extractGraphQLErrorIfExists(
          createClaim({
            variables: {
              input: {
                organizationId: shipment.organization.id,
                shipmentId:
                  shipment?.__typename === 'Shipment'
                    ? shipment?.id
                    : undefined,
                externalShipmentId:
                  shipment?.__typename === 'ExternalShipment'
                    ? shipment?.id
                    : undefined,
                packIds: allPacks
                  ? shipment.packs.map((p) => p.id)
                  : Object.entries(packs)
                      .filter(([, v]) => !!v)
                      .map(([k]) => k),
                type,
                reason: reason || null,
                sourceType,
                sourceUserId: sourceUser?.id,
                currency: shipment.billingCurrency,
                amount: amountVal,
                costToPackfleet: costToPackfleetVal,
                chargeSourceUser,
                chargeSourceUserAmount: chargeSourceUser
                  ? chargeSourceUserAmountVal ?? amountVal
                  : null,
                refundFees,
              },
            },
            refetchQueries: [ListClaimsDocument],
          }),
          'createClaim',
        );

        if (res.error) {
          return res;
        }

        setType('');
        setReason('');
        setSourceType(null);
        setAllPacks(true);
        setAmountsType(false);
        setAmount('');
        setCostToPackfleet('');
        setChargeSourceUser(false);

        return {};
      }}
      confirmText="Add"
      trigger={trigger}
      async
      confirmColor="brand"
    >
      <div>
        <FormLabel className="mb-4">
          Claim type
          <Select<(typeof ClaimTypeOptions)[number]>
            options={ClaimTypeOptions}
            value={ClaimTypeOptions.find((item) => item.label === type)}
            onChange={(item) => {
              setType(item?.label ?? '');

              if (item?.label === WaiveDeliveryFeeClaimType) {
                setRefundFees(true);
                setSourceType(ClaimSourceType.Internal);
                setAmountsType(ClaimAmountType.Credit);
                setAmount(null);
                setCostToPackfleet(null);
              }
            }}
            getOptionLabel={({ label }) => label}
            getOptionValue={({ label }) => label}
            required
          />
        </FormLabel>

        <FormLabel className="mb-4">
          Source type
          <Select<(typeof SourceTypeOptions)[number]>
            options={SourceTypeOptions}
            value={SourceTypeOptions.find((item) => item.value === sourceType)}
            onChange={(item) => setSourceType(item?.value ?? null)}
            getOptionLabel={({ label }) => label}
            getOptionValue={({ value }) => value}
            required
          />
        </FormLabel>

        {sourceType &&
          [ClaimSourceType.Driver, ClaimSourceType.Hub].includes(
            sourceType,
          ) && (
            <FormLabel className="mb-4">
              Source user
              <UsersSelect
                value={sourceUser}
                onChange={(user) => setSourceUser(user)}
                filters={{
                  staffRoles: [StaffRole.Driver, StaffRole.Warehouse],
                }}
              />
            </FormLabel>
          )}

        <FormLabel className="mb-4">
          Comments
          <textarea
            className="w-full rounded border border-primary p-4 shadow-inner"
            value={reason}
            onChange={(e) => setReason(e.currentTarget.value)}
          />
        </FormLabel>

        {type !== WaiveDeliveryFeeClaimType && (
          <>
            <div className="mb-4">
              <FormLabel>Packs</FormLabel>
              <ToggleButtons
                buttons={[
                  { label: 'All Packs', value: true },
                  { label: 'Select Packs', value: false },
                ]}
                value={allPacks}
                onClick={(v) => setAllPacks(v)}
              />
              {!allPacks && (
                <div className="pt-2 flex flex-col gap-2">
                  {shipment.packs.map((pack, i) => (
                    <Checkbox
                      id="packs"
                      key={pack.id}
                      label={`${shipment.trackingNumber}/${i + 1}`}
                      onChange={(v) => {
                        if (v === false || v === true) {
                          setPacks({
                            ...packs,
                            [pack.id]: v,
                          });
                        }
                      }}
                      checked={packs[pack.id]}
                    />
                  ))}
                </div>
              )}
            </div>

            <div className="mb-4">
              <FormLabel>Amounts</FormLabel>
              <ToggleButtons
                buttons={[
                  { label: 'Credit', value: 1 },
                  { label: 'Charge', value: -1 },
                  { label: 'Not yet known', value: false },
                ]}
                value={amountsType}
                onClick={(v) => {
                  if (v !== false) {
                    setAmountsType(v);

                    if (v === ClaimAmountType.Charge) {
                      setRefundFees(false);
                    }
                  } else {
                    setAmountsType(false);
                    setAmount(null);
                    setCostToPackfleet(null);
                  }
                }}
              />
              {amountsType !== false && (
                <div className="pt-2">
                  <FormLabel className="mb-4">
                    Claim Amount
                    <Input
                      id="amount"
                      className="w-full"
                      value={amount ?? ''}
                      onChange={(e) => setAmount(e.currentTarget.value)}
                      placeholder="0.00"
                      inputMode="numeric"
                      pattern="[0-9\.]*"
                    />
                    {amountsType === ClaimAmountType.Credit && (
                      <FormDescription className="mt-1" id="amount">
                        Cost price amount only.
                        <br />
                        Use the option below to refund shipping fees.
                      </FormDescription>
                    )}
                  </FormLabel>
                  <FormLabel className="mb-4">
                    Cost to Packfleet (optional)
                    <Input
                      className="w-full"
                      value={costToPackfleet ?? ''}
                      onChange={(e) =>
                        setCostToPackfleet(e.currentTarget.value)
                      }
                      placeholder="0.00"
                      inputMode="numeric"
                      pattern="[0-9\.]*"
                    />
                  </FormLabel>
                </div>
              )}
              {amountsType === ClaimAmountType.Credit && (
                <div className="pt-2">
                  <FormLabel className="mb-4">
                    Charge Source
                    <ToggleButtons
                      buttons={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ]}
                      value={chargeSourceUser}
                      onClick={(v) => setChargeSourceUser(v)}
                    />
                  </FormLabel>
                </div>
              )}
              {chargeSourceUser && (
                <FormLabel className="mb-4">
                  Amount to be charged to source user
                  <Input
                    className="w-full"
                    value={chargeSourceUserAmount ?? ''}
                    onChange={(e) =>
                      setChargeSourceUserAmount(e.currentTarget.value)
                    }
                    placeholder={amount ?? '0.00'}
                    inputMode="numeric"
                    pattern="[0-9\.]*"
                  />
                </FormLabel>
              )}
              {amountsType === ClaimAmountType.Credit && (
                <div className="pt-2">
                  <FormLabel className="mb-4">
                    Refund Shipping Fees
                    <ToggleButtons
                      buttons={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ]}
                      value={refundFees}
                      onClick={(v) => setRefundFees(v)}
                    />
                  </FormLabel>
                </div>
              )}
            </div>
          </>
        )}
        {amountsType && (
          <div className="py-2 border-y border-y-primary">
            {amountsType === ClaimAmountType.Credit ? (
              <dl>
                <dt>Total Refunded Amount</dt>
                <dd>
                  {formatMoney(
                    parsedAmount +
                      (refundFees ? shipment.billingAmount ?? 0 : 0),
                    currency,
                  )}
                </dd>
                <dt>Original Shipping Fee</dt>
                <dd>{formatMoney(shipment.billingAmount ?? 0, currency)}</dd>
              </dl>
            ) : null}
          </div>
        )}
      </div>
    </Dialog>
  );
};
